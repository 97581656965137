import { faUpload, faDownload, faEdit, faTrashAlt, faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useData } from '../../provider/dataProvider';
import { useAuth } from '../../provider/authProvider';

import React from 'react';
import ModalContent from './ModalContent';

export default function ActionButton({ groupname = "Personal Files", name, isDir = false, handleActions }) {

  const [modal, setModal] = useState(false)
  const [action, setAction] = useState();
  const { updateData } = useData();
  const { user } = useAuth();
  const toggleModal = () => {
    if (modal) {
      updateData();
    }
    setModal(!modal);
  }

  const callAction = (action) => {
    setAction(action)
    toggleModal()
  }

  if (modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }

  return (

    <>
      {modal && (
        <ModalContent groupname={groupname} toggleModal={toggleModal} action={action} name={name} handleAction={handleActions} />
      )}
      {/*   <div className="tooltip">
        <span onClick={() => callAction('rename')} data-testid="edit-button">
          <FontAwesomeIcon icon={faEdit} />
        </span>
        <span className="tooltiptext">rename</span>
      </div>
      */}
      {/*<span onClick={() => callAction('move')} data-testid="move-button">
  <FontAwesomeIcon icon={faFolderOpen} />
</span>*/}
      {(user) && user?.scope?.includes("dev") ?
        <>
          <div className="tooltip">
            <span onClick={() => callAction("delete")} data-testid="delete-button">
              <FontAwesomeIcon icon={faTrashAlt} />
            </span><span className="tooltiptext">delete</span></div></> : ""}
      {/*
          <div className="tooltip">
            <span onClick={() => callAction("download")} data-testid="download-button">
              <FontAwesomeIcon icon={faDownload} />
            </span><span className="tooltiptext">download</span></div>*/}
      {isDir &&
        <div className="tooltip">
          <span onClick={() => callAction("upload")} data-testid="upload-button">
            <FontAwesomeIcon icon={faUpload} />
          </span><span className="tooltiptext">upload</span></div>}
      {isDir &&
        <div className="tooltip">
          <span onClick={() => callAction("create subfolder")} data-testid="create-subfolder-button">
            <FontAwesomeIcon icon={faFolderPlus} />
          </span><span className="tooltiptext">add subfolder</span></div>}

    </>
  )
}