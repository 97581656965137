import React, { useState } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { useAuth } from '../provider/authProvider';
import { useTCP } from '../provider/TCPProvider';
import { log, LogLevel } from './../utils/Logger';
const FileUploader = () => {

  const { TCPData } = useTCP();
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadUrl, setUploadUrl] = useState(null);
  const { token } = useAuth();
  const uploadFile = async (body, url, f) => {
    //if (!uploadUrl || !file) {
    // log(LogLevel.ERROR,'Upload URL or file missing.');
    //  return;
    //}
    try {
      // Step 2: Upload the file to the obtained URL
      await axios.put(url, f, {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setUploadProgress(progress);
        },
      });

      // Step 3: Notify the server that the upload is complete
      await axios.post(TCPData + '/upload/singlepart/complete', body, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });

      // Reset file, URL, and progress after successful upload
      setFile(null);
      setUploadUrl(null);
      setUploadProgress(0);
      alert('File uploaded successfully!');
    } catch (error) {
      log(LogLevel.ERROR, 'Error uploading file:', error);
    }
  };

  const onDrop = async (acceptedFiles) => {
    for (const selectedFile of acceptedFiles) {

      const body = {
        uri: selectedFile.name // Replace with the user-chosen URI
        //size: selectedFile.size,
      }


      try {
        // Step 1: Get upload URL from the server
        const { data } = await axios.post(TCPData + '/upload/singlepart', body, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
        setUploadUrl(data.url);
        setFile(selectedFile);
        log(LogLevel.INFO, data.url)
        await uploadFile(body, data.url, selectedFile)
      } catch (error) {
        log(LogLevel.ERROR, 'Error getting upload URL:', error);
      }


    };
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div>
      <div {...getRootProps()} style={dropzoneStyles}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the file here ...</p>
        ) : (
          <p>Drag 'n' drop a file here, or click to select a file</p>
        )}
      </div>
      {file && (
        <div>
          <p>Selected File: {file.name}</p>
          <progress value={uploadProgress} max="100" />
        </div>
      )}
    </div>
  );
};

const dropzoneStyles = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
};

export default FileUploader;