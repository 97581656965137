import React, { useState, useEffect } from 'react';
import pCloudSdk from "pcloud-sdk-js"
import "./../components/actions/ModalContent.css";
import "./../components/actions/ModalFileSelector.css";
import { log, LogLevel } from '../utils/Logger';
const PcloudFileList = ({ data, client }) => {

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [foldersContent, setFoldersContent] = useState({});
  const [expanded, setExpanded] = useState();
  const [checkedItems, setCheckedItems] = useState({});

  const handleCheckboxChange = (event, file) => {
    event.stopPropagation();
    log(LogLevel.INFO, 'click')
    log(LogLevel.INFO, Object.keys(file))

    const isSelected = selectedFiles.includes(file);
    if (isSelected) {
      setSelectedFiles(selectedFiles.filter(selectedFile => selectedFile !== file));
    } else {
      setSelectedFiles([...selectedFiles, file]);
    }
    log(LogLevel.INFO, selectedFiles)
  };

  const fetchData = async (folder) => {
    try {
      const result = await client.listfolder(parseInt(folder.id.slice(1)));
      const newFoldersContent = { ...foldersContent };
      newFoldersContent[folder.id] = result.contents;
      setFoldersContent(newFoldersContent);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const toggleFolder = (folder) => {
    if (!foldersContent[folder.id]) {
      fetchData(folder)
    }
  };


  const handleClick = (e, folder) => {
    setExpanded({
      ...expanded,
      [folder.folderid]: !expanded[folder.folderid]
    });
  };

  const Folder = ({ folder, level }) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleClick = (e, folder) => {
      e.preventDefault()
      e.stopPropagation()
      setIsOpen(!isOpen);
      toggleFolder(folder);
    };

    return (
      <div>
        <div className="accordion-header" style={{ paddingLeft: level * 20, cursor: 'pointer', marginRight: '5px' }} onClick={(event) => handleClick(event, folder)}>
          {isOpen ? '-' : '+'} {folder.name + " " + folder.id}
        </div>

        {isOpen && foldersContent[folder.id] && foldersContent[folder.id].map(item => (
          item.isfolder ? <Folder key={item.id} folder={item} level={level + 1} /> :
            <div key={item.id}>
              <input
                type="checkbox"
                checked={selectedFiles.includes(item.path)}
                onChange={() => { }}
                onClick={(event) => handleCheckboxChange(event, item.path)}
              />
              {item.name}
            </div>
        ))}

      </div>
    );
  };

  const handleSubmit = () => {
    for (const file in selectedFiles) {
      if (file) {
        log(LogLevel.INFO, Object.keys(selectedFiles[file]))
      }
    }
  }


  return (
    <div className="accordion" style={{ padding: "5px", marginBottom: '20px' }}>
      {data.map(item => (
        item.isfolder ? <Folder level={1} key={item.id} folder={item} /> :
          <div key={item.id}>
            <input
              type="checkbox"
              checked={selectedFiles.includes(item)}
              onChange={() => { }}
              onClick={(event) => handleCheckboxChange(event, item)}
            />
            {item.name}
          </div>
      ))}
      <button onClick={handleSubmit}>Submit</button>
    </div>
  );
};



export default PcloudFileList;