
export const MenuItems = [
  {
    Title: "Products",
    url: "/",
    cName: "nav-links",
  },
  {
    Title: "Account",
    url: "/account",
    cName: "nav-links",
    nscope: "demo"
  },
  {
    Title: "Storage",
    url: "/storage",
    cName: "nav-links",
    scope: "storage"
  },
  {
    Title: "Processes",
    url: "/processes",
    cName: "nav-links",
  },
  {
    Title: "Licenses",
    url: "/licenses",
    cName: "nav-links",
    nscope: "demo"
  },
  {
    Title: "Logout",
    url: "/logout",
    cName: "nav-links-mobile",
  }
];
