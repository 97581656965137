import Waiting from "../components/Waiting";
import Ring from "../components/Ring";
import { capitalizeFirstLetter } from "../utils/string";
import { useLics } from "../provider/licsProvider";
import { log, LogLevel } from './../utils/Logger';

export default function Licenses() {

  const { lics, loadingLics } = useLics()

  if (loadingLics) {
    return (
      <Waiting />
    )
  } else {
    if (lics && lics.length > 0) {
      lics.map((license) => { log(LogLevel.INFO, license.name) })
      return (
        <div data-testid="licenses-page-component" style={{ padding: "30px", borderTopLeftRadius: "3px", backgroundColor: "rgba(255, 255, 255, 0.9)", maxHeight: 'calc(1.2em * 35)', overflowY: 'auto' }}>
          <h2>Licenses Information</h2>
          <ul>
            {lics.map((license, index) => (
              < div key={index}>
                <li key={license.id}>
                  <strong>{capitalizeFirstLetter(license.name)} license</strong>  {license.uses} / {license.limit} tokens
                </li>
                <Ring uses={license.uses} limit={license.limit} />
              </div>
            ))
            }
          </ul >
        </div >
      );
    }
    else {
      return (<h2>No license is available</h2>)
    }
  }

};