import React, { useEffect, useState } from "react";
import "./ModalContent.css";
import "./ModalFileSelector.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { useData } from "../../provider/dataProvider";
import { transformToListOfObjects } from "../../utils/listFiles";
import { useAuth } from "../../provider/authProvider";
import { Link } from "react-router-dom";
import UploadModale from "./UploadModale";
import { Row } from "react-bootstrap";
import Waiting from "../Waiting";
import { useNavigate } from "react-router-dom";
import { log, LogLevel } from '../../utils/Logger'
import ModalPcloud from "../externalStorage/ModalPcloud";


export default function ModalFileSelector({ single = false, expanded, setExpanded, selectedGroup, setSelectedGroup, toggleModal, setSelection }) {
  const navigate = useNavigate()
  const [selectedFiles, setSelectedFiles] = useState([]);
  //const [selectedGroup, setSelectedGroup] = useState();
  const [gdata, setGData] = useState({})

  const [pcloudModal, setPcloudModal_] = useState(false)

  const { data, loadingData } = useData()

  const { groups, user } = useAuth()

  const [error, setError] = useState(null);

  const [checkedItems, setCheckedItems] = useState({});

  const [isLoading, setLoading] = useState(false);

  const [allFiles, setAllFiles_] = useState([]);

  const setPcloudModal = () => {
    log(LogLevel.INFO, pcloudModal)
    setPcloudModal_((prev) => !prev);
  };

  const setAllFiles = (val) => {
    var liste = val
    for (let i = 0; i < liste.length; i++) {
      if (liste[i].endsWith("/")) {
        liste.splice(i, 1);
        i--;
      }
    }
    setAllFiles_(liste)
  }

  // Toggle function 
  const toggleNode = name => {
    log(LogLevel.INFO, "Expanded :", expanded)
    setExpanded({
      ...expanded,
      [name]: !expanded[name]
    });
  };

  const onSelect = (selected) => {
    setSelectedFiles(selected);
  };

  const onSubmitSelection = () => {
    log(LogLevel.INFO, "Submit : ", allFiles)
    setSelection(allFiles)
    toggleModal()
  }

  const handleClickSingleFile = (item, event) => {
    event.stopPropagation();
    setCheckedItems(() => {
      const updatedCheckedItems = {}
      updatedCheckedItems[item] = true
      return updatedCheckedItems
    })
  }

  const onSelectedGroup = (e) => {
    if (e == selectedGroup) {
      setSelectedGroup("")
    }
    else {
      setSelectedGroup(e)
    }
    log(LogLevel.INFO, e)
  }

  useEffect(() => {
    if (data) {
      if (data.hasOwnProperty(selectedGroup)) {
        setGData(transformToListOfObjects(data[selectedGroup]));
      }
    } else (setGData([]))

  }, [selectedGroup, data])



  useEffect(() => {
    if (!loadingData && data != null) {
      log(LogLevel.INFO, selectedGroup)
      log(LogLevel.INFO, data)
      log(LogLevel.INFO, data[selectedGroup])
      var allFiles = []
      var mydata = []
      if (data.hasOwnProperty('Personal Files')) {
        mydata = data["Personal Files"]
      }
      log(LogLevel.INFO, mydata.length)
      for (var i = 0; i < mydata.length; i++) {
        var path = mydata[i]['path']
        if (path.split('@')[1]) { path = path.split('@')[1] }
        if (showcheck(path)) {
          allFiles.push(filename(selectedGroup, path))
        }
      }

      for (var j = 0; j < groups.length; j++) {
        const group = groups[j]

        var mydata = []
        if (data.hasOwnProperty(group.name)) {
          mydata = data[group.name]
        }
        for (var i = 0; i < mydata.length; i++) {
          var path = mydata[i]['path']
          if (path.split('@')[1]) { path = path.split('@')[1] }
          if (showcheck(group.name + '@' + path)) {
            allFiles.push(filename(selectedGroup, path))
          }
        }
      }
      setAllFiles(allFiles)
      log(LogLevel.INFO, allFiles)
    }
  }, [checkedItems, data, loadingData])

  log(LogLevel.INFO, gdata)
  log(LogLevel.INFO, groups)

  const handleCheckboxChange = (item, event) => {
    event.stopPropagation();
    setCheckedItems((prevState) => {
      const updatedCheckedItems = { ...prevState };
      if (showcheck(item)) {
        updatedCheckedItems[item] = false;
        for (let other in checkedItems) {
          if (other.startsWith(item)) {
            delete updatedCheckedItems[other];
          }
        }
        return updatedCheckedItems;
      }
      else {
        updatedCheckedItems[item] = true;
        for (let other in checkedItems) {
          if (other.startsWith(item)) {
            updatedCheckedItems[other] = true;
          }
        }
        return updatedCheckedItems;
      }
    });
  };


  const showcheck = (name) => {
    var isin = false;
    if (!(name in checkedItems)) {
      var chemin = name;
      const partiesDuChemin = chemin.split('/');
      let cheminActuel = '';
      for (let i = 0; i < partiesDuChemin.length; i++) {
        cheminActuel += partiesDuChemin[i] + '/';
        if (cheminActuel in checkedItems && checkedItems[cheminActuel] == true) {
          isin = true
        }
        if (cheminActuel in checkedItems && checkedItems[cheminActuel] == false) {
          isin = false
        }
      }
      for (let item in checkedItems) {
        if (item.startsWith(name) && checkedItems[item] == false) {
          isin = false
        }
      }
    } else {
      for (let item in checkedItems) {
        if ((item.startsWith(name)) && checkedItems[item] == false) {
          isin = false;
          return isin
        } else {
          isin = checkedItems[name]
        }
      }
    }
    return isin
  }

  function filename(groupname, prefix = "") {
    if (groupname === "Personal Files") { return prefix }
    else {
      if (prefix.split('@')[1]) { prefix = prefix.split('@')[1] }

      return groupname + '@' + prefix

    }
  }



  const setUploadFiles = (files) => {
    log(LogLevel.INFO, 'FILES')
    log(LogLevel.INFO, files)
    setCheckedItems((prevState) => {
      const updatedCheckedItems = { ...prevState };
      for (var i = 0; i < files.length; i++) {
        updatedCheckedItems[files[i]] = true
      }
      return updatedCheckedItems
    })
    setSelectedGroup("Personal Files")
  }

  const renderTree = (node, level) => {
    return (
      <>
        {level > 1 && (node.name !== "") && (<div className="accordion" key={node.name}
          style={{ cursor: 'pointer' }}>
          <div className="accordion-header" style={{ paddingLeft: level * 20, cursor: 'pointer', marginRight: '5px' }} onClick={() => toggleNode(node.path)}>
            {node.children ? (
              <span style={{ marginRight: '5px' }} className="folder-icon">
                <FontAwesomeIcon icon={expanded[node.path] ? faFolderOpen : faFolder} />
              </span>
            ) :
              (<span className="file-icon"></span>)
            }
            {node.name.split('@')[1] || node.name}
            {(single && node.isDir) ? "" : <input className='check-box'
              type="checkbox"
              checked={showcheck(filename(selectedGroup, node.path)) || false}
              onChange={() => { }}
              onClick={(event) => {
                if (single && !node.isDir) {
                  return handleClickSingleFile(filename(selectedGroup, node.path), event)
                }
                else { return handleCheckboxChange(filename(selectedGroup, node.path), event) }
              }}

            />}
          </div>
        </div>)}
        {(level < 2 || expanded[node.path]) &&
          node.children &&
          node.children.map(childNode => renderTree(childNode, level + 1))}
      </>
    );
  };


  if (pcloudModal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }


  return (


    <>
      {loadingData ? <Waiting /> :

        <div className="modal" style={{ marginTop: "20px" }}>
          <div className="overlay"></div>
          <div className="modal-content" style={{ marginTop: "20px", maxHeight: 'calc(1.2em * 40)' }} >
            <button className="close-modal" onClick={toggleModal}>x
            </button>
            {user?.scope?.includes('storage') ?
              < div >
                {single ? 'Select your file' : 'Select your files'}
                < div className="content-wrapper" style={{ marginTop: "20px", width: "500px", maxHeight: 'calc(1.2em * 10)', overflowY: 'auto' }}>
                  <div onClick={() => onSelectedGroup("Personal Files")}
                    key={-1}
                    style={{
                      fontWeight: "Personal Files" === selectedGroup ? 'bold' : 'normal',
                      fontSize: "Personal Files" === selectedGroup ? '1.2em' : '1em',
                      cursor: 'pointer',
                      padding: "5px",
                      marginTop: "15px",
                    }}


                  ><span style={{ marginRight: '5px' }} className="folder-icon">
                      <FontAwesomeIcon icon={selectedGroup == "Personal Files" ? faFolderOpen : faFolder} />
                    </span>{"Personal Files"} </div>
                  {selectedGroup == "Personal Files" ?
                    <div className="accordion" style={{ padding: "5px", marginBottom: '20px' }}>
                      {renderTree(gdata, 0)}
                    </div> : ""}
                </div>
                {groups.map((element, index) => (
                  <>
                    <div onClick={() => onSelectedGroup(element.name)}
                      key={index}
                      style={{
                        fontWeight: element.name === selectedGroup ? 'bold' : 'normal',
                        fontSize: element.name === selectedGroup ? '1.2em' : '1em',
                        cursor: 'pointer',
                        padding: "5px"
                      }}
                    > <span style={{ marginRight: '5px' }} className="folder-icon">
                        <FontAwesomeIcon icon={selectedGroup == element.name ? faFolderOpen : faFolder} />
                      </span> {element.name}
                    </div>
                    {selectedGroup == element.name ?
                      <div className="accordion" style={{ padding: "5px", marginBottom: '20px', maxHeight: 'calc(1.2em * 10)', overflowY: 'auto' }}>
                        {renderTree(gdata, 0)}
                      </div> : ""}
                  </>
                ))}

                <p style={{ marginTop: '10px' }}>
                  <UploadModale single={single} setUploadFiles={setUploadFiles} />
                </p>
              </div> : "Contact us to get a TCP storage or use your personal Pcloud or Dropbox account !"}

            <button disabled={(Object.keys(checkedItems) < 1)} className="submit" onClick={onSubmitSelection}>Submit</button>
            <Row style={{ color: "black", background: "white" }}>
              {(Object.keys(checkedItems) < 1) ?
                <></> : <div style={{ padding: "10px", borderRadius: "10px" }}>{allFiles.length} fichiers sélectionnés :</div>}
            </Row>
            <Row style={{ color: "black", background: "white", maxHeight: 'calc(1.2em * 10)' }}>
              {!(Object.keys(checkedItems) < 1) ?
                <ul>{Object.keys(checkedItems).map((item) => (<li>{checkedItems[item] ? item : <del>{item}</del>}</li>))}</ul>
                : <div style={{ padding: "10px", borderRadius: "10px" }}>No files selected.</div>}
            </Row>
            {/*}
            <div className="other-storage" style={{ display: "flex", flexDirection: "crow", alignItems: "center", justifyContent: "center" }}>
              <img onClick={() => setPcloudModal()} style={{ padding: "10px", width: '15%' }} src={"../../images/pcloud_logo512x512.png"} />
              <img style={{ padding: "10px", width: '15%' }} src={"../../images/dropbox_logo400x400.png"} />
            </div>
            {pcloudModal &&
              <ModalPcloud />
            }*/}
          </div >


        </div >
      }   </>
  );
}
