import { useLoaderData } from "react-router-dom"
import { useNavigation } from "react-router-dom";
import React, { useState } from 'react';
import DarkTable from '../components/DisplayTableProcesses';
import Waiting from "../components/Waiting";
import { useProc } from "../provider/procsProvider";


export default function Processes() {

  const { procs, loadingProc } = useProc();
  const [filteredData, setFilteredData] = useState([]);

  const [selectedApps, setSelectedApps] = useState([]); // État local pour les valeurs sélectionnées
  const [minDate, setMinDate] = useState(''); // Date minimale
  const [maxDate, setMaxDate] = useState(''); // Date maximale
  const [tagFilter, setTagFilter] = useState(''); // Filtre par mot-clé pour la colonne "tags"
  const [selectAll, setSelectAll] = useState(false); // État pour savoir si "Tout sélectionner" est coché ou non
  // Fonction pour gérer la sélection des cases à cocher
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (value === 'all') {
      // Si la case "Tout sélectionner" est cochée, mettre à jour l'état de toutes les cases
      setSelectAll(checked);
      setSelectedApps(checked ? procs.map(proc => proc.app) : []); // Sélectionner toutes les apps ou vider la sélection
    } else {
      // Si une case autre que "Tout sélectionner" est cochée ou décochée, mettre à jour la sélection
      setSelectedApps(prevSelected => {
        if (checked && !prevSelected.includes(value)) {
          return [...prevSelected, value];
        } else if (!checked && prevSelected.includes(value)) {
          return prevSelected.filter(app => app !== value);
        }
        return prevSelected;
      });
    }
  };

  // Fonction pour filtrer les données en fonction de la plage de dates
  const handleDateFilter = () => {
    // Convertir les dates en objets Date
    const minDateObj = new Date(minDate);
    const maxDateObj = new Date(maxDate);
    if (!loadingProc) {
      return procs.filter((proc) => {
        const procLaunchedDate = new Date(proc.launched);
        return (!minDate || procLaunchedDate >= minDateObj) && (!maxDate || procLaunchedDate <= maxDateObj);
      })
    }
    else {
      return []
    }
    ;
  };

  // Fonction pour filtrer les données en fonction du mot-clé dans les tags
  const handleTagFilter = () => {
    return tagFilter ? procs.filter((proc) => proc.tags.includes(tagFilter)) : procs;
  };

  // Filtrer les données en fonction des valeurs sélectionnées, de la plage de dates et du mot-clé dans les tags
  const filteredProcs = selectedApps.length > 0 ? handleDateFilter().filter((proc) => selectedApps.includes(proc.app)) : handleDateFilter();
  const finalFilteredProcs = tagFilter ? handleTagFilter() : filteredProcs;

  return (
    <>
      {loadingProc ? (
        <Waiting />
      ) : (
        <div align="center" style={{ marginBottom: '10px', color: '#fff', fontWeight: "bold" }}>
          <h1>Processes</h1>
          {/* Section des cases à cocher */}
          <div style={{ marginBottom: '10px' }}>
            {/* Case à cocher "Tout sélectionner" */}
            <label style={{ marginRight: '10px' }}>
              <input type="checkbox" value="all" onChange={handleCheckboxChange} checked={selectAll} />
              All
            </label>
            {/* Cases à cocher pour chaque valeur d'app */}
            {Array.from(new Set(procs.map((proc) => proc.app))).map((app) => (
              <label key={app} style={{ marginRight: '10px' }}>
                <input type="checkbox" value={app} onChange={handleCheckboxChange} checked={selectedApps.includes(app)} />
                {app}
              </label>
            ))}
          </div>
          <br />
          {/* Sélecteurs de date */}
          <div style={{ marginBottom: '10px' }}>
            <label style={{ marginRight: '10px' }}>
              Since :
              <input type="date" value={minDate} onChange={(e) => setMinDate(e.target.value)} />
            </label>
            <label style={{ marginRight: '10px' }}>
              Until :
              <input type="date" value={maxDate} onChange={(e) => setMaxDate(e.target.value)} />
            </label>
          </div><br />
          {/* Champ de saisie pour filtrer par mot-clé dans les tags */}
          <div style={{ marginBottom: '10px' }}>
            <input type="text" placeholder="Filter by tags" value={tagFilter} onChange={(e) => setTagFilter(e.target.value)} />
          </div><br />
          {/* Table avec les données filtrées */}
          <div style={{ padding: '30px', borderRadius: '3px', backgroundColor: 'rgba(255, 255, 255, 0.9)', maxHeight: 'calc(1.2em * 35)', overflowY: 'auto' }}>
            <DarkTable data={finalFilteredProcs} />
          </div>
        </div>
      )}
    </>
  );
}
