
import { useState, useEffect } from "react";
import { useSocket } from "../../provider/wsProvider";
import { v4 as uuidv4 } from 'uuid';
import Waiting from "../Waiting";


import { log, LogLevel } from '../../utils/Logger';

export default function RenameModale({ name, toggleModal, handleAction }) {

  var segments = name.split("/");

  const [newName, setNewName] = useState(segments[-1]);
  const { socket } = useSocket();
  const [messagesList, setMessagesList] = useState([]);
  const [responseData, setResponseData] = useState(null);
  const [isLoading, setLoading] = useState(false)
  const [mid, setMid] = useState();

  const handleChange = (e) => {
    setNewName(e.target.value);
  };

  const handleSave = () => {


    var directory = segments.slice(0, segments.length - 1).join("/");
    var dest = newName
    if (name[name.length - 1] === "/") {
      dest = segments.slice(0, segments.length - 2).join("/") + '/' + newName + '/';
    }
    else if (directory) {
      dest = segments.slice(0, segments.length - 1).join("/") + '/' + newName
    }
    if (dest.charAt(0) === '/') {
      dest = dest.substring(1);
    }
    const body = { "src": name, 'dest': dest };
    var newmid = uuidv4()
    setMid(newmid)
    socket.emit('data', { 'id': newmid, 'action': 'move', 'body': body })
    setLoading(true)
    // alert('This feature is currently under development.')
    //toggleModal()
    log(LogLevel.INFO, body)
  };

  useEffect(() => {
    if (socket) {
      socket.on('json', (data) => {
        const jsonData = JSON.parse(data)
        if (jsonData && jsonData.info && jsonData.info.moves) {
          log(LogLevel.INFO, JSON.stringify(jsonData.info.moves));
        }
        toggleModal()
        handleAction()
      })
      return () => {
        socket.off('websocket');
      };
    }
  }, [socket]);


  return (
    <>
      {isLoading ?
        <>
          <Waiting size="50" />
        </>
        :
        <>
          <input
            type="text"
            value={newName}
            onChange={handleChange}
            placeholder="Nouveau nom"
            style={{ margin: "10px", width: '90%' }}
          />
          <div className="modal-buttons">
            <button style={{ margin: '8px' }} onClick={toggleModal}>Annuler</button>
            <button style={{ margin: '8px' }} onClick={handleSave}>Enregistrer</button>
          </div>
        </>

      }
    </>
  );

}
