


export default function Waiting({ size = "100" }) {
  return (
    <div data-testid="waiting-component" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <img
        src="https://s3.fr-par.scw.cloud/static.tcp/WAIT-TCP-unscreen.gif"
        alt="Waiting TheCrossProduct"
        width={size}
      /></div>)
}