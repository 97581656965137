import React, { useEffect } from 'react';
import { useAuth } from '../../provider/authProvider';
import { useParams } from 'react-router-dom';
import AutoGeneratedForm from './AutoGeneratedForm';
import { useState } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import DataViewer from '../DataViewer';
import { useNavigation } from 'react-router-dom';
import Waiting from '../Waiting';
import { useTCP } from './../../provider/TCPProvider'
import { log, LogLevel } from '../../utils/Logger';
export default function ProductForm() {

  const { token, user, loadingAuth } = useAuth();
  const { TCPApp } = useTCP();
  const { domain, app } = useParams();
  const [jsonData, setJsonData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showStorage, setShowStorage] = useState(false);

  const url = `${TCPApp}/${domain}/${app}/inputs`;
  log(LogLevel.INFO, url)

  useEffect(() => {
    fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'accept': 'application/json'
      }
    })
      .then(response => log(LogLevel.INFO, response.status) || response)
      .then(response => response.text())
      .then(body => {
        log(LogLevel.INFO, body);
        var jsonBody = JSON.parse(body)
        // ---------------- TODO REMOVE WHEN API WILL ADD THIS IN DOCUMENTATION -------
        if (user?.scope.includes("dev")) {
          jsonBody["content"][0]["content"]["top"]["properties"]["pool"] = {
            "nullable": true,
            "type": "array",
            "items": {
              "type": "string",
            },

          }
          jsonBody["content"][0]["content"]["top"]["properties"]["ram"] = {
            "nullable": true,
            "type": "string",
          }
          jsonBody["content"][0]["content"]["top"]["properties"]["mem"] = {
            "nullable": true,
            "type": "string",
          }
        }
        // ---------------- TODO REMOVE WHEN API WILL ADD THIS IN DOCUMENTATION -------
        setJsonData(jsonBody); setIsLoading(false);
      })
  }, [loadingAuth])

  return (<>
    <div style={{ color: "#fff" }}>
      <h2>{app.replace("_", " ").toUpperCase()}</h2>
    </div>
    <Row>
      <Col >
        {isLoading ? <Waiting /> : <AutoGeneratedForm jsonSchema={jsonData} domain={domain} app={app} />}
      </Col>
    </Row>
  </>

  );
};
