import { Container } from "react-grid-system";
import { Link } from "react-router-dom";
import { useState } from "react";

import { log, LogLevel } from '../../utils/Logger';
export default function DomainContainer({ theme }) {

  log(LogLevel.INFO, theme)

  const [isHovered, setIsHovered] = useState(false);

  const url_file = "./images/".concat(theme, '.png')
  const handleClick = () => {
    log(LogLevel.INFO, `Le produit a été cliqué ${theme}`)
  }

  const url_domain = `/products/${theme}`

  return (
    <Link to={url_domain} style={{ textDecoration: 'none', color: "black" }}>
      <Container onClick={handleClick}
        style={{
          padding: "20px",
          boxShadow: '1px 2px 9px gray',
          textAlign: "center",
          backgroundColor: isHovered ? "rgba(255, 255, 255)" : "rgba(255, 255, 255,0.7)",
          color: isHovered ? 'black' : 'rgba(0, 0, 0)',
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        fluid>
        <div>
          <h1>{theme.toUpperCase()}</h1>
        </div>
      </Container>
    </Link>
  );
};