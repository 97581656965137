import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/free-solid-svg-icons';
import "./GroupList.css";

export default function GroupList({ groups, selectedGroup, setNewGroup }) {

  const calculateColor = (index, totalItems) => {
    const hue = (index * (360 / totalItems)) + 50 % 360;
    return `hsl(${hue}, 40%, 70%)`;
  };

  return (
    <div>
      <ul className="liste-noms">
        {groups.map((nom, index) => (
          <li
            key={index}
            onClick={() => setNewGroup(nom)}
            className={nom === selectedGroup ? 'nom-selectionne' : ''}
          >
            <FontAwesomeIcon icon={faFolder} className="icon" style={{ color: calculateColor(index, groups.length) }} />
            <span className="nom-groupe">{nom}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

