
import { useState, useEffect } from "react";
import { useSocket } from "../../provider/wsProvider";
import { v4 as uuidv4 } from 'uuid';
import Waiting from "../Waiting";
import { log, LogLevel } from '../../utils/Logger';


export default function MoveModale({ name, toggleModal, handleAction }) {

  const [newName, setNewName] = useState(name);
  const { socket } = useSocket();
  const [messagesList, setMessagesList] = useState([]);
  const [responseData, setResponseData] = useState(null);
  const [isLoading, setLoading] = useState(false)
  const [mid, setMid] = useState();

  const handleChange = (e) => {
    setNewName(e.target.value);
  };

  const handleSave = () => {
    setLoading(true)
    const body = { "src": name, "dest": newName };
    var newmid = uuidv4()
    setMid(newmid)
    socket.emit('data', { 'id': newmid, 'action': 'move', 'body': { 'src': name, 'dest': newName } })
    log(LogLevel.INFO, body)
  };

  useEffect(() => {
    if (socket) {
      socket.on('json', (data) => {
        const jsonData = JSON.parse(data)
        if (jsonData && jsonData.info && jsonData.info.moves) {
          log(LogLevel.INFO, JSON.stringify(jsonData.info.moves));
        }
        toggleModal()
        handleAction()
      })
      return () => {
        socket.off('websocket');
      };
    }
  }, [socket]);


  return (
    <>
      {isLoading ?
        <>
          <Waiting size="50" />
        </>
        :
        <>
          <input
            type="text"
            value={newName}
            onChange={handleChange}
            placeholder="Nouveau nom"
            style={{ width: '100%' }}
          />
          <div className="modal-buttons">
            <button style={{ margin: '8px' }} onClick={toggleModal}>Annuler</button>
            <button style={{ margin: '8px' }} onClick={handleSave}>Enregistrer</button>
          </div>
        </>

      }
    </>
  );
}