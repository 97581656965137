export function transformToListOfObjects(inputList) {

    function removeLeadingSlash(string) {
        return string.replace(/^\//, '');
    }
    const folders = { name: "root" };
    
    if (inputList) {
    
    inputList.forEach(entireItem => {
        var group=""
        var item=entireItem
        if (entireItem.path.includes("@")) {
            group=entireItem.path.split('@')[0]+"@"
            item.path=entireItem.path.split('@')[1]
        } 
        const sitem=removeLeadingSlash(item.path)
        const pathParts = sitem.split('/');
        let currentFolder = folders;
        let currentPath = '';
  
        pathParts.forEach((folderName, index) => {
            currentPath += (currentPath === '' ? '' : '/') + folderName;
            if (index === pathParts.length - 1) {
                // Dernière partie du chemin, c'est un fichier
                if (!currentFolder.children) {
                    currentFolder.children = [];
                }
                currentFolder.children.push({
                    name: folderName,
                    size: (item.size).toString(),
                    modified: item.last_modified,
                    path: currentPath,
                    isDir: false
                });
            } else {
                // C'est un dossier
                if (!currentFolder.children) {
                    currentFolder.children = [];
                }
                let foundFolder = currentFolder.children.find(child => child.name === folderName);
                if (!foundFolder) {
                    foundFolder = { name: folderName, isDir: true, children: [] , path: currentPath+'/'};
                    currentFolder.children.push(foundFolder);
                }
                currentFolder = foundFolder;
            }
        });
    });
    markEmptyFolders([folders]);
    }
    return {"name":"root","children":[folders]}
    ;
  }

export function markEmptyFolders(folders) {
    folders.forEach(folder => {
        if (folder.children) {
            markEmptyFolders(folder.children);
            if (folder.children.length === 0) {
                folder.isEmpty = true;
            }
        }
    });
  }