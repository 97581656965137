import DomainSelector from "../components/domain/DomainSelector";
import Waiting from "../components/Waiting";
import { useProduct } from "../provider/prodProvider";
import { log, LogLevel } from "../utils/Logger";

export default function Home() {
  const { products, loadingProd } = useProduct()
  log(LogLevel.INFO, JSON.stringify(products))

  if (loadingProd) {
    return (<Waiting />)
  }
  else if (!products || !Object.keys(products)) {
    return (
      <div style={{ color: "#fff" }}>
        To access our products please <a href="mailto:contact@thecrossproduct.com">contact@thecrossproduct.com</a>.
      </div>
    )
  } else {
    return (
      <>
        <div data-testid="home-page-component">
          <DomainSelector obj={Object.keys(products)} />
        </div>
      </>
    );
  }
};




