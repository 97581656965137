import { faAddressCard, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../provider/authProvider";
import Waiting from "../components/Waiting";

export default function Account() {

  const { user } = useAuth();
  if (user) {
    return (
      <div key={user.mail} style={{ backgroundColor: "rgba(255, 255, 255, 0.9)", padding: "50px", borderRadius: "2px", alignItems: 'center', height: '50vh' }} className="user-info">
        <h2>
          <span>
            <FontAwesomeIcon icon={faAddressCard} /> User account
          </span>
        </h2>
        <ul>
          <li key={user.mail} >
            {user.mail}
          </li>
        </ul> {
          user?.group && user.group.map((group) => (
            <div key={group.id} className="groups-info">
              <h2>
                <span>
                  <FontAwesomeIcon icon={faUserGroup} /> Groups
                </span>
              </h2>
              <ul  >

                <li key={group.id} style={{ padding: "5px" }}>
                  <strong>{group.name} :</strong> {group.descr}
                </li>

              </ul>
            </div>))
        }
      </div >
    )
  }
  else {
    return (
      <Waiting />
    )
  }
};