const LogLevel = {
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error'
};

const log = (level, message) => {
  
  const isProduction = process.env.REACT_APP_ENV === 'production';
  if (isProduction && level !== LogLevel.ERROR) {
    return; 
  }

  switch (level) {
    case LogLevel.INFO:
      console.log(`[INFO] ${message}`);
      break;
    case LogLevel.WARNING:
      console.warn(`[WARNING] ${message}`);
      break;
    case LogLevel.ERROR:
      console.error(`[ERROR] ${message}`);
      break;
    default:
      console.log(`[${level.toUpperCase()}] ${message}`);
  }
};

export { log, LogLevel };