
import React from 'react';
import DonutChart from 'react-donut-chart';

export default function Ring({ uses, limit }) {


  var part = parseInt(uses)
  var rest = parseInt(limit - uses)
  return (

    <DonutChart data={[
      {
        label: 'Uses',
        value: part,
      },
      {
        label: 'Limit',
        value: rest,
        isEmpty: true,
      },
    ]}
    />
  );
};



