import { Container } from "react-grid-system";
import { Link } from "react-router-dom";
import { useNavigation } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { log, LogLevel } from '../../utils/Logger';
export default function ProductMiniature({ theme, sujet }) {

  const navigation = useNavigation();
  const [imageExists, setImageExists] = useState(true);

  const [isHovered, setIsHovered] = useState(false);


  const url_file = "../images/".concat(theme, "_", sujet, '.png')
  const handleClick = () => {
    log(LogLevel.INFO, `Le produit a été cliqué ${theme}_${sujet}`)
  }

  let location = useLocation();

  const url_product = `${sujet}`
  const handleImageError = () => {
    setImageExists(false);

  };

  return (
    <Link to={url_product} style={{ textDecoration: 'none', color: "black" }}>
      <Container
        style={{
          padding: "25px",
          boxShadow: '1px 2px 9px gray',
          borderRadius: "1px",
          textAlign: "center",
          backgroundColor: isHovered ? "rgba(255, 255, 255)" : "rgba(255, 255, 255,0.7)",
          color: isHovered ? 'black' : 'rgba(0, 0, 0)',
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        fluid>
        {imageExists ? (
          <div>
            <img
              src={url_file}
              className="logo img-fluid"
              alt={theme.concat(' ', sujet)}
              width="100%"
              onError={handleImageError}
            />
          </div>
        ) :
          <div>
            <img
              src={"../images/".concat(theme, '.png')}
              className="logo img-fluid"
              alt={theme}
              width="100%"
              onError={handleImageError}
            />
          </div>
        }
        <h3>{sujet.replace("_", ' ').replace("_", ' ').toUpperCase()}</h3>
      </Container>
    </Link>
  );
};