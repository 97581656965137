import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import { useTCP } from "../../provider/TCPProvider";
import { log, LogLevel } from '../../utils/Logger';

export const ResetForm = ({ onResetPasswordRequest }) => {

  const [message, setMessage] = useState(null);
  const { TCPAuth } = useTCP();

  const handleResetPassword = async (event) => {
    event.preventDefault();
    log(LogLevel.INFO, event.target.resetEmail.value);
    setMessage("");
    try {
      const response = await fetch(TCPAuth + '/reset_password', {
        method: 'POST',
        body: JSON.stringify({ "mail": event.target.resetEmail.value }),
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      } else {
        setMessage("Check your mail !");

      }

      log(LogLevel.INFO, await response.json());
    } catch (error) {
      log(LogLevel.ERROR, 'Error occurred while resetting the password: ' + error.message);
      setMessage('Error occurred while resetting the password: ' + error.message);
    }
  };

  return (
    <form className="reset-form" onSubmit={handleResetPassword}>
      <h2>Reset password</h2>
      <div className="form-group">
        <label htmlFor="resetEmail">Enter your email.</label>
        <input type="email" name="resetEmail" />
      </div>
      <Button
        type="submit"
        variant="primary"
      >
        Receive the password reset email.
      </Button>
      {message && <p style={{ color: 'white', fontStyle: "italic" }}>{message}</p>}
      <p style={{ marginTop: '10px', }}>
        <a href="#" data-testid='link-login-form' onClick={onResetPasswordRequest} style={{ color: '#fff', textDecoration: 'none' }}>Return to login page</a>
      </p>
    </form>)
}