import React, { useState } from "react";

import { capitalizeFirstLetter } from "../../utils/string";
import "./ModalContent.css";

import DeleteModale from "./DeleteModale";
import MoveModale from "./MoveModale";
import DownloadModale from "./DownloadModale";
import UploadModale from "./UploadModale";
import RenameModale from "./RenameModale";
import CreateFolderModale from "./CreateFolderModal";

export default function ModalContent({ groupname, toggleModal, action, name, handleAction }) {

  let content;
  switch (action) {
    case 'delete':
      content = <DeleteModale name={name} toggleModal={toggleModal} handleAction={handleAction} />;
      break;
    case 'move':
      content = <MoveModale name={name} toggleModal={toggleModal} handleAction={handleAction} />;
      break;
    case 'rename':
      content = <RenameModale name={name} toggleModal={toggleModal} handleAction={handleAction} />;
      break;
    case 'download':
      content = <DownloadModale name={name} toggleModal={toggleModal} groupname={groupname} />;
      break;
    case 'upload':
      content = <UploadModale name={name} toggleModal={toggleModal} handleAction={handleAction} />;
      break;
    case 'create subfolder':
      content = <CreateFolderModale name={name} toggleModal={toggleModal} handleAction={handleAction} />;
      break;

    default:
      content = <div>Unknown action</div>;
  }

  return (
    <> <div className="modal">

      <div className="overlay"></div>
      <div className="modal-content">
        <h2>{capitalizeFirstLetter(action)}</h2>
        <div className="content-wrapper" >
          {content}
        </div>
        <button className="close-modal" onClick={toggleModal}> x
        </button>
      </div>
    </div>
    </>
  );
}