import axios from "axios";
import Waiting from "../Waiting";
import { useAuth } from "../../provider/authProvider";
import { useEffect, useState } from "react";
import { useTCP } from "../../provider/TCPProvider";
import { log, LogLevel } from '../../utils/Logger';

function raccourcirListeChaines(listeChaines, longueurMax) {
  return listeChaines.map(chaine => {
    if (chaine.length > longueurMax) {
      return chaine.substring(0, 8) + " [...] " + chaine.substring(chaine.length - 25);
    } else {
      return chaine;
    }
  });
}



export default function DownloadModale({ groupname, name, toggleModal }) {
  const { TCPData } = useTCP();
  const [files, setFiles] = useState([]);
  const { token } = useAuth();
  const [isLoading, setLoading] = useState(true);
  const [sdirs, setSDirs] = useState([]);
  const [sfiles, setSFiles] = useState([]);
  const [error, setError] = useState(null);

  var body = {}


  useEffect(() => {

    if (name[name.length - 1] === "/") {
      if (groupname === "Personal Files") {
        body = {
          items_per_page: 10000,
          prefix: [name]
        }
      } else {
        body = {
          groups: [groupname],
          items_per_page: 10000,
          prefix: [name.split("@")[1]]
        }
      }

      const fetchData = async (currentBody) => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        try {
          const response = await axios.post(
            TCPData,
            JSON.stringify(currentBody),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          log(LogLevel.INFO, response)
          if (response.status === 200) {
            if ("files" in response.data) {
              setSFiles(response.data.files);
            }
          } else {
            log(LogLevel.INFO, "Erreur de requête. Statut :", response.status);
          }
          setLoading(false);
        } catch (error) {
          log(LogLevel.ERROR, "Une erreur s'est produite :", error);
          setLoading(false);
        }
      }
      fetchData(body);
    }
    else {
      setSFiles([name]);
      setLoading(false);
    }
  }, [isLoading]);

  const handleDownload = () => {
    sfiles.forEach(async (item) => {
      try {
        // Effectue une requête POST avec Axios
        const body = { "uri": item };
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const response = axios.post(TCPData + '/download', JSON.stringify(body), {
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then((response) => {
            log(LogLevel.INFO, response)
            if (response.status >= 200 && response.status < 300) {
              log(LogLevel.INFO, response.data.url)
              var downloadUrl = response.data.url;
              const link = document.createElement('a');

              link.target = "_blank"
              link.href = downloadUrl;
              link.click();
            } else {
              log(LogLevel.INFO, 'Erreur de requête. Statut :', response.status);
            }
          })
      } catch (error) {
        // Gérer les erreurs
        log(LogLevel.ERROR, 'Erreur lors de la requête POST :', error);
      }
    });
    toggleModal();
  };

  return (
    <>
      {isLoading ?
        <>
          <Waiting size="50" />
        </>
        :
        <><ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
          {raccourcirListeChaines(sfiles, 30).map((item, index) => (
            <li style={{ marginBottom: '5px', fontSize: '14px', color: '#888' }} key={index}>{item}</li>
          ))}
        </ul> {(sfiles.length > 1) ? sfiles.length.toString() + " fichiers vont être téléchargés" : "va être téléchargé"}
          <div className="modal-buttons">
            <button style={{ margin: '8px' }} onClick={toggleModal}>Annuler</button>
            <button style={{ margin: '8px' }} onClick={handleDownload}>Continuer</button>
          </div>
        </>

      }
    </>
  );

}