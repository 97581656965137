
import { Link } from "react-router-dom";
import { Container, Row, Col } from 'react-grid-system';
import DomainContainer from "./DomainContainer";
export default function DomainSelector({ obj }) {

  return (

    <Container style={{ padding: "20px" }} fluid>
      <Row >
        {obj.map((domaine, index) => (
          <Col key={index} lg={4} sm={12} style={{ padding: "50px" }}>
            <DomainContainer key={index} theme={domaine} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};