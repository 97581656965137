
import pCloudSdk from "pcloud-sdk-js"
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import PcloudFileList from "../components/PcloudFileList";

export default function PcloudAuth() {
  const hash = window.location.hash;
  const params = new URLSearchParams(hash.substring(1));
  const access_token = params.get('access_token');

  const tokenType = params.get('token_type');
  const userId = params.get('userid');
  global.locationid = params.get('locationid');
  const hostname = params.get('hostname');

  const [data, setData] = useState([]);
  const client = pCloudSdk.createClient(access_token);

  useEffect(() => {

    const fetchData = async () => {
      try {

        const result = await client.listfolder(0);
        setData(result.contents);
        console.log(result.contents)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <div style={{ backgroundColor: "#fff" }}>
      {data.length > 0 ? <PcloudFileList data={data} client={client} /> : ""}
    </div>);
}
