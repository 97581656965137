import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import io from 'socket.io-client';
import { useAuth } from "./authProvider";
import { log, LogLevel } from '../utils/Logger';
const WSContext = createContext();

const WSProvider = ({ children }) => {

  const { token } = useAuth();
  const [socket, setSocket] = useState();

  useEffect(() => {
    const newSocket = io('https://ws.thecrossproduct.xyz', { extraHeaders: { Authorization: `Bearer ${token}` } });
    setSocket(newSocket);
    return () => {
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socket) {
      log(LogLevel.INFO, 'Websocket connected:', socket.connected);
      return () => {
        socket.off('websocket');
      };
    }
  }, [socket]);


  // Memoized value of the authentication context
  const contextValue = useMemo(
    () => ({
      socket,
      setSocket,
    }),
    [socket]
  );

  // Provide the authentication context to the children components
  return (
    <WSContext.Provider value={contextValue}>{children}</WSContext.Provider>
  );
};

export const useSocket = () => {
  return useContext(WSContext);
};

export default WSProvider;
