import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useAuth } from "./authProvider";
import { log, LogLevel } from './../utils/Logger';
import { useTCP } from './TCPProvider';

const DataContext = createContext();

const DataProvider = ({ children }) => {

  const { TCPData } = useTCP()
  const [data, setData] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  log(LogLevel.INFO, "DATA provider")

  const addValue = (key, value) => {
    log(LogLevel.INFO, "ADD VALUE")
    log(LogLevel.INFO, key)
    log(LogLevel.INFO, value)
    setData(prevData => ({
      ...prevData,
      [key]: value
    }));
  };

  const { token, user, groups } = useAuth()

  const transformerEnObjets = (liste) => {
    return liste.map(function (dossier) {
      return {
        path: dossier,
        isDir: true,
        created: "",
        size: "-"
      };
    });
  }

  const updateData = () => {
    setLoadingData(true)
    async function fetchPersonalData() {
      try {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const body = { "expands_info": true, "items_per_page": 100000 };
        const response = await axios.post(TCPData, JSON.stringify(body), {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (response.status === 200) {
          const jsonResponse = response.data;
          if (jsonResponse.hasOwnProperty('files') && jsonResponse.hasOwnProperty('dirs')) {
            addValue("Personal Files", jsonResponse["files"].concat(transformerEnObjets(jsonResponse["dirs"])));
          } else if (jsonResponse.hasOwnProperty('files')) {
            addValue("Personal Files", jsonResponse["files"]);
          }
          else { addValue("Personal Files", []) }
        } else {
          log(LogLevel.INFO, 'Erreur de requête. Statut :', response.status);
        }
        log(LogLevel.INFO, "ICI")
      } catch (error) {
        log(LogLevel.ERROR, 'Une erreur s\'est produite :', error);
      }
      setLoadingData(false)
    }
    log(LogLevel.INFO, 'LALAL')
    // Group
    async function fetchData() {
      for (let i = 0; i < groups.length; i++) {
        const group = groups[i].name;
        const gbody = { "groups": [group], "personal": false, "expands_info": true, "items_per_page": 100000 };
        log(LogLevel.INFO, "group:" + group)
        try {
          const response = await axios.post(TCPData, JSON.stringify(gbody), {
            headers: {
              'Content-Type': 'application/json'
            }
          });
          log(LogLevel.INFO, "response")
          log(LogLevel.INFO, response)
          if (response.status === 200) {
            const jsonResponse = response.data;
            if (jsonResponse.hasOwnProperty('files') && jsonResponse.hasOwnProperty('dirs')) {
              addValue(group, jsonResponse["files"].concat(transformerEnObjets(jsonResponse["dirs"])));
            } else if (jsonResponse.hasOwnProperty('files')) {
              addValue(group, jsonResponse["files"]);
            } else { addValue(group, []) }
            setLoadingData(false)
          } else {
            log(LogLevel.INFO, 'Erreur de requête. Statut :', response.status);
          }
        } catch (error) {
          log(LogLevel.ERROR, 'Une erreur s\'est produite :', error);
        }
      }

    }
    fetchPersonalData()
    if (groups.length > 0) {
      fetchData();
    }
  }

  useEffect(() => {
    if (user?.scope?.includes('storage')) {
      updateData()
      log(LogLevel.INFO, "DATA provider use Effect")
    } else {
      log(LogLevel.INFO, 'Scope Storage missing')
      setData(null)
      setLoadingData(false)
    }
  }, [user]);

  // Memoized value of the data context
  const contextValue = useMemo(
    () => ({
      data,
      setData,
      updateData,
      loadingData,
    }),
    [data, updateData, loadingData]
  );

  // Provide the data context to the children components
  return (
    <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>
  );
};


export const useData = () => {
  return useContext(DataContext);
};

export default DataProvider;
