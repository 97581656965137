import React, { useState } from 'react';
import { LoginForm } from "../components/form/LoginForm";
import { ResetForm } from "../components/form/ResetForm";

const Login = () => {
  const [resetPasswordRequested, setResetPasswordRequested] = useState(false);

  const handleResetPasswordRequest = (event) => {
    event.preventDefault();
    setResetPasswordRequested((prev) => !prev);
  };

  return (
    <div data-testid="login-page-component" className="login-page">
      <div className="login-container">
        <div className="logo">
          <img src="https://s3.fr-par.scw.cloud/static.tcp/WhiteLogoBorder.png" alt="Logo de l'application" />
        </div> {resetPasswordRequested ? "" :
          <div data-testid="login-form"><LoginForm onResetPasswordRequest={handleResetPasswordRequest} /></div>}
        {!resetPasswordRequested ? "" :
          <div data-testid="reset-form"><ResetForm onResetPasswordRequest={handleResetPasswordRequest} /></div>}
      </div>
    </div>
  );
}
export default Login;

