import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import Navbar from "../components/NavBar";
import { Container, Row, Col } from 'react-grid-system';

export const ProtectedRoute = () => {
  const { token } = useAuth();

  // Check if the user is authenticated
  if (!token) {
    // If not authenticated, redirect to the login page
    return <Navigate to="/login" />;
  }

  // If authenticated, render the child routes
  return (
    <><Navbar />
      <Container style={{ padding: "20px" }} fluid>
        <Row >
          <Col lg={1} md={0} xs={0}></Col>
          <Col lg={10} md={12} xs={12}> <Outlet /></Col>
        </Row>
      </Container>
    </>
  );
};


