


export function convertSize(bytes) {
    if (isNaN(bytes) || bytes < 0) return "-";

    const k = 1024;
    const sizes = ['octets', 'Ko', 'Mo', 'Go'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    if (i === -Infinity) return "0 octets";
    return parseFloat((bytes / Math.pow(k, i)).toFixed(0)) + ' ' + sizes[i];
}