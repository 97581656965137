import { useNavigate } from "react-router-dom";
import { useAuth } from "../../provider/authProvider";
import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useTCP } from "../../provider/TCPProvider";


export const LoginForm = ({ onResetPasswordRequest }) => {

  const { TCPAuth } = useTCP();
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { setToken } = useAuth();
  const navigate = useNavigate();

  const handleLogin = (token) => {
    setToken(token);
    navigate("/", { replace: true });
  };
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleFormSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const response = await fetch(TCPAuth + '/login', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + btoa(e.target.username.value + ':' + e.target.password.value)
        }
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else if (response.status === 401) {
            setError('Incorrect credentials. Please try again.');
            setLoading(false);
            return "";
          } else {
            setError("Something wrong ... " + response.text);
            setLoading(false);
            return "";
          }
        })
        .then((data) => {
          if (data != "") {
            const token = data?.token;
            if (token) {
              handleLogin(token)
            } else {
              setError("Something wrong ... Please contact us");
              setLoading(false);
            }
          }
          setLoading(false);
        });
    } catch (error) {
      setError('Error occurred : ' + error.message);
    }
  };

  return (
    <form className="login-form" onSubmit={!isLoading ? handleFormSubmit : null}>
      <h2>Login</h2>
      <div className="form-group">
        <label htmlFor="username">Username</label>
        <input type="text" id="username" name="username" />
      </div>
      <div className="form-group">
        <label htmlFor="password">Password</label>
        <div style={{ display: 'flex' }}>
          <input type={showPassword ? 'text' : 'password'} id="password" name="password" autoComplete="current-password" />
          <span style={{ color: '#fff', padding: "15px" }}><FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} onClick={toggleShowPassword} style={{ cursor: 'pointer' }} /></span>
        </div>
      </div>
      <Button
        type="submit"
        variant="primary"
        disabled={isLoading}
      >
        {isLoading ? "Please wait ..." : "Log in"}
      </Button>
      <p style={{ marginTop: '10px', }}>
        <a href="#" data-testid='link-reset-password' onClick={onResetPasswordRequest} style={{ color: '#fff', textDecoration: 'none' }}>Forgot password ?</a>
      </p>
      {error && <p style={{ color: 'white' }}>{error}</p>}
    </form>
  )
}