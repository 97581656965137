import { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import Waiting from "../Waiting";
import { log, LogLevel } from '../../utils/Logger';
import axios from "axios";
import { useAuth } from "../../provider/authProvider";
import { useTCP } from "../../provider/TCPProvider";

export default function DeleteModale({ name, toggleModal, handleAction }) {

  const { token } = useAuth();
  const { TCPData } = useTCP();
  const [newName, setNewName] = useState(name);
  const [messagesList, setMessagesList] = useState([]);
  const [responseData, setResponseData] = useState(null);
  const [isLoading, setLoading] = useState(false)
  const [mid, setMid] = useState();

  const handleChange = (e) => {
    setNewName(e.target.value);
  };

  const handleRemove = () => {
    const body = { "uri": name };
    var newmid = uuidv4()
    try {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      const response = axios.post(TCPData + '/remove', JSON.stringify(body), {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          log(LogLevel.INFO, response)
          if (response.status >= 200 && response.status < 300) {
            log(LogLevel.INFO, "ok")
          } else {
            log(LogLevel.INFO, 'Erreur de requête. Statut :', response.status);
          }
        })
    } catch (error) {
      // Gérer les erreurs
      log(LogLevel.ERROR, 'Erreur lors de la requête POST :', error);
    }
    setLoading(false)
    toggleModal()
    handleAction()
  };

  return (
    <>
      {isLoading ?
        <>
          <Waiting size="50" />
        </>
        :
        <>
          {name} va etre supprimé.
          <div className="modal-buttons">
            <button style={{ margin: '8px' }} onClick={toggleModal}>Annuler</button>
            <button style={{ margin: '8px' }} onClick={handleRemove}>Continuer</button>
          </div>
        </>

      }
    </>
  );




  return (
    <><h1>Move {name}</h1>



    </>)
}