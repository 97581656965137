import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { log, LogLevel } from './../utils/Logger';
import { useTCP } from './TCPProvider';
import { getTCP } from "../api/apiTCP";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  // State to hold the authentication token
  const [token, setToken_] = useState(localStorage.getItem("token"));
  const [user, setUser_] = useState();
  const [groups, setGroups_] = useState();
  const [loadingAuth, setLoadingAuth] = useState(true);
  const { TCPAuth } = useTCP()

  // Function to set the authentication token
  const setToken = (newToken) => {
    setToken_(newToken);
  };

  // Function to set the groups list
  const setGroups = (newGroups) => {
    setGroups_(newGroups);
  };

  const setUser = (newUser) => {
    setUser_(newUser);
  };

  const updateAuth = (token) => {
    log(LogLevel.INFO, "updateAuth")
    setLoadingAuth(true)
    getTCP(TCPAuth, token)
      .then((resp) => {
        setUser(resp)
        if (resp?.group) {
          setGroups(resp.group)
        }
        else {
          setGroups([])
        }
        setLoadingAuth(false)
      })
      .catch(error => {
        log(LogLevel.ERROR, error.message)
        setLoadingAuth(false)
      })
  }

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
      updateAuth(token)
    } else {
      localStorage.removeItem("token");
    }
  }, [token]);

  // Memoized value of the authentication context
  const contextValue = useMemo(
    () => ({
      token,
      setToken,
      groups,
      setGroups,
      updateAuth,
      loadingAuth,
      user,
    }),
    [token, groups, user]
  );

  // Provide the authentication context to the children components
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
