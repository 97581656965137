
import { Container, Row, Col } from 'react-grid-system';
import { useState } from "react";
import { useAuth } from "../provider/authProvider";
import DataViewer from "../components/DataViewer";
import GroupList from "../components/GroupList";
import Waiting from "../components/Waiting";
import UploadModale from "../components/actions/UploadModale";

import { log, LogLevel } from './../utils/Logger';

export const get_listGroupsName = (groups) => {
  var listGroupsName = (groups).map(function (objet) {
    return objet.name
  });
  listGroupsName.unshift("Personal Files");
  return listGroupsName
}

export function get_prefixgroup(selectedGroup) {
  if (selectedGroup === "Personal Files") {
    return ''
  }
  else { return selectedGroup + "@" }
}

export default function Storage() {

  const { groups, loadingAuth } = useAuth();
  const [selectedGroup, onSelectGroup] = useState("Personal Files");

  log(LogLevel.INFO, "render Storage page ", selectedGroup)

  return (
    <>
      {loadingAuth ? <Waiting /> :
        <Container data-testid='storage-page-component' style={{ padding: "20px" }} fluid>
          {/* <Row style={{ justifyContent: "flex-end", padding: "30px", borderTopLeftRadius: "3px", backgroundColor: "rgba(255, 255, 255, 0.9)" }}>
            <UploadModale name={get_prefixgroup(selectedGroup)} />
  </Row>*/}
          <Row style={{ padding: "0px", }}>
            <Col lg={2} md={12} xs={12} style={{ padding: "10px", borderTop: "2px solid #eee", borderRight: "2px solid #eee", borderBottom: "2px solid #eee", backgroundColor: "rgba(255, 255, 255, 0.9)", paddingBottom: "30px" }}>
              {groups ? <div data-testid="group-list" ><GroupList key={get_listGroupsName(groups)} groups={get_listGroupsName(groups)} selectedGroup={selectedGroup} setNewGroup={onSelectGroup} /></div> : ""}
            </Col>
            <Col lg={10} md={12} xs={12} style={{ backgroundColor: "rgba(255, 255, 255, 0.9)", borderTop: "2px solid #eee" }}>
              <div data-testid="dataviewer">
                <DataViewer selectedGroup={selectedGroup} />
              </div>
            </Col>
          </Row>
        </Container>
      }
    </>
  )

}

