import React, { useState, useCallback, useEffect, useRef } from 'react';
import pCloudSdk from 'pcloud-sdk-js';

const Test = () => {
  const [client, setClient] = useState()
  const [access_token, setToken] = useState()
  const [locationid, setLocationid] = useState()


  const listFile = () => {
    client.listfolder(0).then(function (metadata) {
      metadata.contents.forEach(function (item, n) {
        if (!item.isfolder) {
          console.log(item.fileid)
        }
      });
    })
  }

  const handleLoginPcloud = () => {
    pCloudSdk.oauth.initOauthToken({
      client_id: 'awQ0HDvVn3J',
      redirect_uri: 'http://127.0.0.0:3000/test/pcloud',
      receiveToken: function (token, id) {
        console.log(token);
        access_token = token;
        locationid = id || 1;
        client = pCloudSdk.createClient(token);
      }
    })
  }


  return (
    <div>
      <button onClick={handleLoginPcloud}>Login Pcloud</button>
    </div>
  );
};

export default Test;