import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useAuth } from "./authProvider";
import { log, LogLevel } from '../utils/Logger';
import { useTCP } from './TCPProvider';
import { getTCP } from "../api/apiTCP";
const licsContext = createContext();

const LicsProvider = ({ children }) => {

  const { token } = useAuth()
  const { TCPLics } = useTCP()
  const [lics, setLics] = useState({})
  const [loadingLics, setLoadingLics] = useState(true);


  const updateLics = (token) => {
    setLoadingLics(true)
    getTCP(TCPLics + '/licenses', token)
      .then((resp) => {
        setLics(resp["licenses"])
        setLoadingLics(false)
      })
      .catch(error => {
        log(LogLevel.ERROR, error.message)
        setLoadingLics(false)
      })
  }


  useEffect(() => {
    updateLics(token)
  }, [token]);


  const contextValue = useMemo(
    () => ({
      lics,
      setLics,
      loadingLics
    }),
    [lics, loadingLics]
  );

  // Provide the authentication context to the children components
  return (
    <licsContext.Provider value={contextValue}>{children}</licsContext.Provider>
  );
};

export const useLics = () => {
  return useContext(licsContext);
};

export default LicsProvider;
