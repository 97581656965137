import React, { useState } from "react";

import "./../actions/ModalContent.css";

export default function ModalBody({ toggleModal, body }) {
  const copyToClipboard = () => {
    // Copie le contenu dans le presse-papiers
    navigator.clipboard.writeText(body);
    // Vous pouvez également utiliser document.execCommand('copy') pour une compatibilité plus large, mais il est déconseillé par MDN.
  };
  return (
    <> <div className="modal">
      <div className="overlay"></div>
      <div className="modal-content" style={{ minHeight: 'calc(1.2em * 5)' }} >
        <button onClick={copyToClipboard}>
          Copy
        </button>
        <div className="modal-wrapper" style={{ marginTop: "20px", width: "500px", maxHeight: 'calc(1.2em * 10)', overflowY: 'auto' }} >
          {body}
        </div>
        <div style={{ marginTop: "0px" }}>
          <button className="close-modal" onClick={toggleModal}> x
          </button>
        </div>
      </div>
    </div>

    </>
  );
}