import logo from './logo.svg';
import './App.css';
import AuthProvider from "./provider/authProvider";
import TCPProvider from "./provider/TCPProvider";
import DataProvider from "./provider/dataProvider";
import WSProvider from "./provider/wsProvider";
import ProdProvider from './provider/prodProvider';
import ProcProvider from './provider/procsProvider';
import Routes from "./routes";

function App() {
  return (
    <TCPProvider>
      <AuthProvider>
     
          <DataProvider>
            <ProdProvider>
              <ProcProvider>
                <Routes />
              </ProcProvider>
            </ProdProvider>
          </DataProvider>
       
      </AuthProvider>
    </TCPProvider>
  );
}

export default App;
