import ProductMiniature from "./ProductMiniature"
import { useLoaderData } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from 'react-grid-system';
import { useNavigation } from "react-router-dom";
import Waiting from "../Waiting";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useProduct } from "../../provider/prodProvider";
export default function ProductMiniatureSelector() {

  const { domain } = useParams();
  const { products } = useProduct()
  const obj = products

  const apps = obj[domain]
  const navigation = useNavigation();

  if (navigation.state === "loading") {
    return (
      <Waiting />
    )
  }

  return (
    <div>
      <Container style={{ padding: "20px" }} fluid>

        <Col lg={4} >
          <Row >
            <Link to="/">
              <span style={{ color: "#fff", fontSize: '3em' }}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </span>
            </Link>
          </Row>
        </Col>
        <Row >
          {apps.map((nom, index) => (
            <Col xl={3} lg={4} md={6} xs={12} style={{ padding: "10px" }}>
              <ProductMiniature key={index} theme={domain} sujet={nom} />
            </Col>
          ))
          }
        </Row>
      </Container>

    </div>
  );
};

